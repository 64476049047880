<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Microservices" />
    <!--EOC-->
    <v-row class="mb-3">
      <template v-for="(service, index) of Object.values(services)">
        <v-col cols="4" :key="index">
          <Microservice :service="service"/>
        </v-col>
      </template>
    </v-row>
    <v-card color="orange" dark>
      <v-card-title>Future Microservices</v-card-title>
      <v-card-text>
        <ul>
          <li>Account</li>
          <li>Sales</li>
          <li>Product</li>
          <li>Payment</li>
          <li>Commission</li>
          <li>Content</li>
          <li>Event</li>
          <li>Marketing</li>
          <li>Reward</li>
          <li>Notification</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[components]
import Microservice from "@/services/developer/components/PageDeveloperMicroservice/Microservice";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    Microservice,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[services]
    services: [],
    //EOC
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Microservices",
      to: { name: "PageDeveloperMicroservice" },
      exact: true,
    });
    //EOC
    //BOC:[services]
    this.services = this.$_.clone(this.$service);
    Object.keys(this.services).forEach((serviceKey) => {
      var service = this.services[serviceKey];
      service = {
        key: serviceKey,
        port: "Unknown",
        urlApi: service,
        urlSwagger: service.substring(0, service.length - 3),
      };
      this.services[serviceKey] = service;
    });
    //EOC
    //BOC: customise values
    /*
    not yet setup: 
    1: [account] sso, account
    2: [lead] lms, profiling
    3: [extra] hrdc, chat
    4: [system] crm, developer
    5: sales, payment
    6:
    7:
    8:

    */
    this.services.crm = {
      ...this.services.crm,
      port: 8000,
    };
    this.services.sso = {
      ...this.services.sso,
      port: 8001,
    };
    this.services.developer = {
      ...this.services.developer,
      port: 8070,
    };
    this.services.lead = {
      ...this.services.lead,
      port: 8002,
    };
    this.services.log = {
      ...this.services.log,
      port: 8095,
    };
    this.services.hrdc = {
      ...this.services.hrdc,
      port: 8010,
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
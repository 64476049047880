<template>
  <v-card class="mb-1 fill-height" style="position: relative">
    <v-card-title>{{ service.key }}</v-card-title>
    <v-card-subtitle>Port {{ service.port }}</v-card-subtitle>
    <v-card-text>
      <v-chip v-if="api.isLoading" x-small color="grey" dark>Connecting...</v-chip>
      <v-chip v-else-if="api.isError" x-small color="red" dark>{{api.error}}</v-chip>
      <v-chip v-else x-small color="green" dark>Success</v-chip>
      <div>{{ service.urlApi }}</div>
    </v-card-text>
    <div style="height: 30px"></div>
    <v-card-actions style="position: absolute; bottom: 0; right: 0">
      <v-btn color="primary" :href="service.urlSwagger" text target="_blank"
        >View in Swagger</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    service: {
      type: Object,
    },
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
  }),
  watch: {
    //
  },
  created() {
    //BOC:[api]
    this.api.setUrl(`${this.$service[this.service.key]}/v1/en/system/checkConnection`);
    this.api.setCallbackCompleted(() => {
      //
    });
    this.api.fetch();
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>